<!-- Localized -->
<template>
<div
	class="notification"
	v-if="countNumber"
	:title="`New notifications: ${countNumber}`"
>
	<mailIcon class="notificatin__icon"/>
	<div class="notification__count">{{countNumber}}</div>
</div>
</template>

<script>
import mailIcon from "@/assets/icons/dashboard/mail.svg";

export default {
		components: { mailIcon },
		props: {
			count: {
				type: [String, Number],
				default: 0,
			},
		},
		computed: {
			countNumber() {
				return +this.count;
			}
		},
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.notification {
	position: relative;
	// cursor: default;
	&__count {
		font-size: toRem(12px);
		height: toRem(18px);
		min-width: toRem(18px);
		padding: 0 1px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 2px solid #0D69D5;
		box-sizing: border-box;
		position: absolute;
    transform: translate(50%, -40%);
    top: 0;
    right: 0;
		background-color: #fff;
		font-weight: 700;
	}
}
</style>